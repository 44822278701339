/* Overrides */
.form-control {
  font-size: 17px;
  height: calc(2.25rem + 23px);
}
/* Pump sequence buttons */
.pump-buttons {
  line-height: 40px;
  font-size: 25px;
  text-align: center;
  background: transparent;
}

.pump-buttons:focus,
.pump-buttons:active,
.pump-buttons:hover {
  background: transparent !important;
  box-shadow: none !important;
  transform: unset !important;
}

.pump-buttons:focus.pump-buttons:active.pump-buttons:hover {
  background: transparent !important;
  box-shadow: none !important;
  transform: unset !important;
}
@media only screen and (max-width: 600px) {
  .pump-buttons {
    padding: 0;
  }
}

/* Pump Monitor */
.monitor {
  margin-bottom: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.monitor .pump {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: 210% 210%;
  background-position: top right;
  transition: all 0.15s ease;
  box-shadow: none;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.monitor .pump.loading {
  background: #344675;
  background-color: #344675;
  color: #ffffff;
  background-image: linear-gradient(to bottom left, #344675, #263148, #344675);
}

.monitor .pump.off {
  background: #fd5d93;
  background-color: #fd5d93;
  color: #ffffff;
  background-image: linear-gradient(to bottom left, #fd5d93, #ec250d, #fd5d93);
}

.monitor .pump.on {
  background: #00f2c3;
  background-color: #00f2c3;
  color: #ffffff;
  background-image: linear-gradient(to bottom left, #00f2c3, #3ed7bb, #00f2c3);
}

.monitor .pump i {
  font-size: 40px;
}

.monitor .pump p {
  font-size: 50px;
  line-height: 50px;
}

.monitor .pump h1 {
  margin: 3rem 0;
}

.monitor .bomb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  text-transform: uppercase;
  border-radius: 6rem;
  position: relative;
  min-width: 25%;
  min-height: 3.5rem;
  margin-top: 1rem;
  border: 5px solid #acb1b7;
  box-shadow: 1px 1px 10px #434343;
}
.monitor .bomb img {
  position: absolute;
  left: -1rem;
}
.monitor .bomb p {
  margin: 0;
  padding-left: 3rem;
}
.monitor .unavailable {
  background: #757575;
}
.monitor .off {
  background: #c20d19;
}
.monitor .on {
  background: #539f44;
}
.monitor .bomb.on img {
  left: unset;
  right: -1rem;
}
.monitor .bomb.on p {
  padding-left: 0;
  padding-right: 3rem;
}

.device-item-selector {
  background: #27293d;
  color: white;
  border: none;
}

@media only screen and (max-width: 600px) {
  .monitor .bomb {
    min-width: 85%;
  }
}

.big-paragraph {
  font-size: 30px;
}

.analytics-filters {
  font-size: 25px;
  line-height: 0.5;
}

/* Fix outline buttons */
[class*="btn-outline-"] {
  background: transparent;
}

/* Alarm form */
.alarm-form {
  background: #27293d;
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  border-radius: 15px;
}

.filter-label {
  font-size: 1.1rem !important;
  color: #58c1f9 !important;
}

.table-responsive {
  overflow-x: auto;
  overflow-y: auto;
}
.remove-margin-top {
  margin-top: 0px !important;
}
